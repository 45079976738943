<template>

  <div class="col-12 col-xl-4 d-flex flex-column mt-3 mt-lg-0">
    <h5 class="font-main text-secondary">Publicación</h5>
    <div class="card flex-grow-1">
      <div class="card-body">

        <div class="row mb-3">
          <div class="col-12">
            <label class="text-secondary">Fecha de publicación</label><br/>
            <small class="text-secondary">Selecciona la fecha que deseas publicar el video</small>
            <v-date-picker
                color="blue"
                class="date"
                @dayclick="onDayClick"
                style="width: 100%"
                :min-date="min_date"
                :model-config="modelConfig"
                v-model="calendar"
                :value="sec_tres.date"
                :key="key_calendar"
              />
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    sec_tres: {
      type: Object,
    },
    editing: {
      type: Boolean,
    },
  },
  watch: {
    sec_tres(sec_tres){
      this.calendar = sec_tres.date;
    },
    editing() {
      if (this.editing) {
        this.prepareToEdit()
      }
    },
  },
  data() {
    return {
      calendar: '',
      min_date:'',
      key_calendar:0,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD', // Uses 'iso' if missing
      },
    };
  },
  created() {
    
  },
  methods: {
    onDayClick(day) {
      this.sec_tres.date = day.id;
    },
    prepareToEdit(){
      this.calendar = new Date(this.sec_tres.date)
      this.key_calendar += 1;
    }
  },
};
</script>
